import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, SelectChangeEvent } from '@mui/material';

const LanguageSelector: React.FC = () => {
  // Existing functionality remains intact
  const { i18n } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const changeLanguage = (event: SelectChangeEvent<string>) => {
    const lang = event.target.value as string;
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  const languages = {
    en: "English",
    az: "Azerbaijani",
    ru: "Russian",
  };
  
  return (
    <FormControl 
    style={{
      backgroundColor: 'transparent', // Set background to transparent
    }}
  >
    <Select
      variant="standard" // Set variant to standard
      native 
      value={i18n.language}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      onChange={changeLanguage}
      style={{
        appearance: 'none', 
        border: 'none', // Set border to none
        outline: 'none', // Remove focus outline
        boxShadow: 'none', // Remove boxShadow
        backgroundColor: 'transparent', 
        color: 'inherit',
        cursor: 'pointer',
        borderBottom: 'none',
      }}
    >
      {Object.entries(languages).map(([code, name]) => (
        <option key={code} value={code}>
          {isFocused ? name : code.toUpperCase()}
        </option>
      ))}
    </Select>
  </FormControl>
  );
};

export default LanguageSelector;
