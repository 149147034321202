import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const root = document.getElementById('root') as HTMLElement;

const theme = createTheme({
  palette: {
      primary: {
          main: '#4CAF50',
      },
  },
});


declare module 'react-dom' {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let createRoot: (container: HTMLElement) => any;
}

// Using createRoot API
ReactDOM.createRoot(root).render(
<ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
);


