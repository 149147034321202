import { useNavigate } from 'react-router-dom'; // import useHistory
import { AppBar, Toolbar, Typography, Container, IconButton } from '@mui/material';
// import { useCustomTranslation } from '../Helper/Translations';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import LoginIcon from '@mui/icons-material/Person'; // Consider changing the icon as per your design preference.
import MusicNoteIcon from '@mui/icons-material/MusicNote'; // For a piano related icon


function Header() {
  const navigate = useNavigate();
  // const { ct } = useCustomTranslation();

  const handleLoginClick = () => {
    navigate('/login'); // 👈️ Navigate to login page using navigate
};

  return (
    <AppBar position="static" style={{ background: '#2C3E50', boxShadow: '0px 4px 2px -2px gray' }}>
      <Container>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <MusicNoteIcon />
            Piano.az
          </Typography>
          <LanguageSelector />
          <IconButton color="inherit">
            <LoginIcon color="inherit" onClick={handleLoginClick}/>
        
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
