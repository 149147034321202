import React from 'react';
import { useCustomTranslation } from '../../components/Helper/Translations';
import './Home.css';
//import TukazImage from '../../assets/images/img/tukaz.jpg'; // Changed to import
import Soundofharmony from '../../assets/images/img/Soundofharmony.jpg'; // Changed to import

const MainContent: React.FC = () => {
  const { ct } = useCustomTranslation();

  return (
    <main className="photo-album">
      <div className="photo-special">
        <div className="photo" style={{ backgroundImage: `url(${Soundofharmony})` }}></div>
        <div className="photo-description">
          <h3>{ct.tukazName}</h3>
          <p>{ct.tukazDescription}</p>
        </div>
      </div>
    </main>
  );
}

export default MainContent;
