import React, { useState } from "react";
import './SignUpPage.css';

const SignUpPage: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [gender, setGender] = useState<string>("");

  const [emailError, setEmailError] = useState<string>('');

  const handleSignUp = () => {
    // Handle SignUp logic here.
    console.log("Signing up with email: ", email);
  };

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // allows empty string or numbers only
      setMobile(value);
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    // If the email input is empty, don't show error
    if (value.trim() === '') {
      setEmailError('');
    } else if (!validateEmail(value)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };


  return (
    <div className="signup-container">
      <div className="signup-card">
        <h2>Create Account</h2>
        
        <div className="input-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="surname">Surname</label>
          <input
            type="text"
            id="surname"
            placeholder="Enter your surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </div>
        
        <div className="input-group">
        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          className={emailError ? 'input-error' : ''}
        />
        {emailError && <span className="error-message">{emailError}</span>}
      </div>

        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="mobile">Mobile</label>
          <input
            type="text" // keep as text to prevent up/down arrows appearing
            id="mobile"
            placeholder="Enter your mobile number"
            value={mobile}
            onChange={handleMobileChange}
          />
        </div>

        <div className="input-group">
          <label htmlFor="gender">Gender</label>
          <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        
        <button className="signup-btn" onClick={handleSignUp}>Sign Up</button>
      </div>
    </div>
  );
};

export default SignUpPage;
