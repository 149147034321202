import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import './i18n'
import Home from './pages/Home/Home';
import Community from './pages/Community';
import LoginPage from './pages/LoginPage/LoginPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import AboutPage from './pages/AboutPage/AboutPage';



function App() {
  return (
    <Router>
      <div>
        {/* Navigation, Sidebar, Header, etc. can be added here */}
        <Routes>
          <Route path="" Component={Home} />
          <Route path="/community" Component={Community} />
          <Route path="/Login" Component={LoginPage} />
          <Route path="/SignUp" Component={SignUpPage} />
          <Route path="/About" Component={AboutPage} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;


