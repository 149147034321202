import React from 'react';
import MainContent from './MainContent';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Home: React.FC = () => {
 
  return (
    <div>
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default Home;
