import React, { useState } from "react";
import './LoginPage.css';
import { Link } from "react-router-dom";
import { useCustomTranslation } from "../../components/Helper/Translations";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { ct } = useCustomTranslation();


  const handleLogin = () => {
    // Handle login logic here.
    console.log("Logging in with email: ", email);
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Welcome Back!</h2>

        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button className="login-btn" onClick={handleLogin}>Login</button>

        <li>
          <p className="signup-text">Don’t have an account? <span className="signup-link">
            <Link to="/SignUp" className="link">{ct.sign_up} </Link>
          </span>
          </p>
        </li>


      </div>
    </div>
  );
};

export default LoginPage;
