import React from 'react';
import './Footer.css';
import {useCustomTranslation} from '../Helper/Translations';
import InstagramSVGIcon from '../../assets/images/svg/InstagramSVGIcon'; // Adjust the path as needed
import FacebookSVGIcon from '../../assets/images/svg/FacebookSVGIcon'; // Adjust the path as needed

const Footer: React.FC = () => {
    const { ct } = useCustomTranslation();
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-links">
                    <a href="/about">{ct.about}</a>
                    <a href="/contact">{ct.contact}</a>
                    <a href="mailto:info@piano.az">Mail</a>
                </div>
                <div className="social-media">
                    <p> {ct.follow_us}:</p>
                    <a href="https://www.instagram.com/piano_azerbaijan/" target="_blank" rel="noopener noreferrer">
                        <InstagramSVGIcon />
                    </a>
                    <a href="https://www.facebook.com/piano_azerbaijan/" target="_blank" rel="noopener noreferrer">
                        <FacebookSVGIcon />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
