// i18nHelper.ts 
// {t('login')} -> t.login

import { useTranslation } from 'react-i18next';

interface Translations {
    [x: string]: string;
  }

  export const useCustomTranslation = () => {
    const { t, i18n } = useTranslation();

    const ct: Translations = new Proxy({}, {
        get: (_target, prop: string) => {
            return t(prop);
        }
    }) as Translations;

    return { ct, i18n };
};