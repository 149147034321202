
import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const AboutPage: React.FC = () => {
    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <Typography variant="h2" component="h1" gutterBottom>
                    About Us
                </Typography>
                
                <Paper elevation={3} sx={{ p: 4, my: 2 }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Who We Are
                    </Typography>
                    <Typography>
                        Piano.az is a passionate group dedicated to cultivating an appreciation for piano music within the Azerbaijani community. We aim to enrich musical education and create a harmonious space for musicians and enthusiasts alike.
                    </Typography>
                </Paper>
                
                <Paper elevation={3} sx={{ p: 4, my: 2 }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Our Mission
                    </Typography>
                    <Typography>
                        We strive to provide a versatile platform that offers high-quality resources, tutorials, and interactive learning experiences to fuel the passion for music in individuals, regardless of their expertise level.
                    </Typography>
                </Paper>
                
                <Paper elevation={3} sx={{ p: 4, my: 2 }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Future Prospects
                    </Typography>
                    <Typography>
                        We are continuously innovating to expand our platform, host events, and collaborate with music institutions, ensuring an inclusive and inspiring environment for all piano music lovers.
                    </Typography>
                </Paper>
            </Box>
        </Container>
    );
};

export default AboutPage;
